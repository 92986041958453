import { Fragment, useContext, useMemo, useState, useEffect } from 'react';
import { faCog, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col } from 'react-bootstrap';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import Select from 'react-select';
import toast from '../../toast';
import ModalLargePadding from '../LargePadding';
import {
  commonSettings,
  daysList,
  getFrequencyList,
  internalSteps,
  menuItems,
  projectList,
  sendForList,
  stepList,
} from './fixtures';
import {
  TEMPLATE_SETTINGS,
  PROJECT_SETTINGS,
  SEND_EMAIL,
  LIVE_STATUS,
  LIVE,
  EMAIL_FREQUENCY_VALUE,
  ADMIN,
  CANVAS_SETTINGS_FIELD,
  CANVAS_SETTINGS_PROPERTY,
  INPUT_TYPES,
  DYNAMIC_TIMELINE,
  PAST_DUE_STEP,
  SYSTEM_EMAILS,
  INTERNAL_STEPS,
  ALL_STEPS,
  EXTERNAL_STEP_MEMBERS,
} from 'constants';
import { SettingHeader, FixedDynamicTimeline, DropdownContainer, Label } from './styles';
import { CanvasSettings } from 'models';
import FullContext from 'stores/Full/fullContext';
import { Link } from 'react-router-dom';

export default function ModalCanvasSettings({ show, onHide, canvas, onUpdated, isTemplate }) {
  const [dropdownValues, setDropdownValues] = useState({
    sendProjectUpdateTo: null,
    sendProjectUpdateEvery: null,
    sendProjectUpdateFrequency: null,
    sendPastDueEmailFor: null,
    sendPastDueEmailTo: null,
    invitedToProject: null,
    assignedToStepOrTask: null,
    comments: null,
  });

  const [emailSettings, setEmailSettings] = useState({
    projectUpdate: false,
    pastDueStep: false,
    invitedToProject: false,
    assignedToStepOrTask: false,
    comments: false,
  });

  useEffect(() => {
    const findOption = (dropdownValue, list) => list.find(({ value }) => value === dropdownValue);

    const projectUpdate = canvas?.emailSettings?.projectUpdate;
    const pastDueStep = canvas?.emailSettings?.pastDueStep;
    const invitedToProject = canvas?.emailSettings?.invitedToProject;
    const assignedToStepOrTask = canvas?.emailSettings?.assignedToStepOrTask;
    const comments = canvas?.emailSettings?.comments;

    setEmailSettings({
      projectUpdate: projectUpdate?.isEnabled,
      pastDueStep: pastDueStep?.isEnabled,
      invitedToProject: invitedToProject?.isEnabled,
      assignedToStepOrTask: assignedToStepOrTask?.isEnabled,
      comments: comments?.isEnabled,
    });

    setDropdownValues({
      sendProjectUpdateTo: findOption(projectUpdate?.to, projectList),
      sendProjectUpdateEvery: findOption(projectUpdate?.frequencyValue, daysList),
      sendProjectUpdateFrequency: findOption(
        projectUpdate?.frequencyUnit,
        getFrequencyList(projectUpdate?.frequencyValue)
      ),
      sendPastDueEmailFor: findOption(pastDueStep?.for, sendForList),
      sendPastDueEmailTo: findOption(pastDueStep?.to, stepList),
      invitedToProject: findOption(invitedToProject?.to, projectList),
      assignedToStepOrTask: findOption(assignedToStepOrTask?.to, projectList),
      comments: findOption(comments?.to, projectList),
    });
  }, [canvas.emailSettings]);

  const { isCreator, roleAtleast, isFrontLine } = useContext(FullContext);

  const canEdit = isCreator || roleAtleast(ADMIN) || (isFrontLine && !canvas?.emailSettings?.isViewOnly);

  async function toggleCanvasStatus() {
    if (canvas.canvasStatus === LIVE) {
      await unpublishCanvas();
    } else {
      await publishCanvas();
    }
    onUpdated();
  }

  async function unpublishCanvas() {
    const response = await CanvasSettings.unpublish(canvas.id);
    const data = await response.json();
    if (response.status === 400) {
      toast.error(data);
    } else {
      canvas.canvasStatus = data.canvasStatus;
    }
  }

  async function publishCanvas() {
    var data = await CanvasSettings.publish(canvas.id);
    canvas.canvasStatus = data.canvasStatus;
  }

  async function saveCanvasSettings(field, fieldValue, type, property = '') {
    canvas[field] = fieldValue;

    if (type === INPUT_TYPES.SWITCH)
      setEmailSettings((prev) => ({
        ...prev,
        [field]: fieldValue,
      }));

    if (type === INPUT_TYPES.DROPDOWN) {
      setDropdownValues((prev) => ({
        ...prev,
        [field]: fieldValue,
      }));
    }

    const formattedValue = type === INPUT_TYPES.DROPDOWN ? fieldValue.value : fieldValue;

    if (isTemplate) {
      await CanvasSettings.changeCanvasSettingParam(property || field, formattedValue, canvas.id);
    } else {
      await CanvasSettings.changeCustomerCanvasSettingParam(property || field, formattedValue, canvas.id);
    }

    onUpdated(field, fieldValue);
  }

  const renderInfoTextBox = (info) =>
    info ? (
      <div className="ml-2">
        <InfoTextBox notActive={false} width="300px" top="0" zIndex={2}>
          {info.text}
        </InfoTextBox>
      </div>
    ) : null;

  const renderOnOff = (key, isEmailSettings = true) => {
    if (key === DYNAMIC_TIMELINE) {
      return (
        <FixedDynamicTimeline isEnabled={canvas.dynamicTimeline}>
          {canvas.dynamicTimeline ? 'ON' : 'OFF'}
        </FixedDynamicTimeline>
      );
    }

    const isEmailSettingsEnabled = isEmailSettings ? emailSettings[key] : canvas[key];

    return (
      <FixedDynamicTimeline isEnabled={isEmailSettingsEnabled}>
        {isEmailSettingsEnabled ? 'ON' : 'OFF'}
      </FixedDynamicTimeline>
    );
  };

  const formattedStepList = useMemo(() => {
    if (dropdownValues.sendPastDueEmailFor?.label === INTERNAL_STEPS) {
      return stepList.map((step) => {
        if (internalSteps.includes(step.label)) {
          return {
            ...step,
            isDisabled: true,
          };
        }

        return { ...step };
      });
    }

    if (dropdownValues.sendPastDueEmailFor?.label === ALL_STEPS) {
      return stepList.map((step) => {
        if (step.label === EXTERNAL_STEP_MEMBERS) {
          return {
            ...step,
            isDisabled: true,
          };
        }

        return { ...step };
      });
    }

    return stepList;
  }, [dropdownValues.sendPastDueEmailFor]);

  const pluralizedFrequency = useMemo(
    () =>
      dropdownValues.sendProjectUpdateEvery
        ? getFrequencyList(dropdownValues.sendProjectUpdateEvery.value)
        : [],
    [dropdownValues.sendProjectUpdateEvery]
  );

  const renderForm = (args) => {
    const { fieldName, name, info, property } = args;

    const isDisabled = !emailSettings[fieldName] || (!isTemplate && !canEdit);
    const isViewOnlyFrontline = emailSettings[fieldName] && isFrontLine && !isTemplate && !canEdit;

    if (fieldName === SEND_EMAIL) {
      return (
        <div className="text-right">
          <div className="mb-3">
            {canEdit ? (
              <Form.Check
                type="switch"
                id={fieldName}
                name={fieldName}
                checked={emailSettings.projectUpdate}
                onChange={(e) =>
                  saveCanvasSettings(fieldName, e.target.checked, INPUT_TYPES.SWITCH, property)
                }
              />
            ) : (
              renderOnOff(fieldName)
            )}
          </div>

          {emailSettings.projectUpdate && (
            <>
              <div className="d-flex align-items-center mb-3 justify-content-end">
                <Label
                  isDisabled={!emailSettings.projectUpdate}
                  className={`mr-3 ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  Send to
                </Label>
                <DropdownContainer
                  className={`mr-2 common-select ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  <Select
                    value={dropdownValues.sendProjectUpdateTo}
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder=""
                    isDisabled={isDisabled}
                    onChange={(value) =>
                      saveCanvasSettings(
                        CANVAS_SETTINGS_FIELD.PROJECT_UPDATE_TO,
                        value,
                        INPUT_TYPES.DROPDOWN,
                        CANVAS_SETTINGS_PROPERTY.sendProjectUpdateTo
                      )
                    }
                    options={projectList}
                  />
                </DropdownContainer>
              </div>

              <div className="d-flex align-items-center justify-content-end">
                <Label
                  isDisabled={isDisabled}
                  className={`mr-3 ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  Every
                </Label>
                <DropdownContainer
                  className={`mr-2 day-select ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  <Select
                    value={dropdownValues.sendProjectUpdateEvery}
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder=""
                    isDisabled={isDisabled}
                    onChange={(value) =>
                      saveCanvasSettings(
                        CANVAS_SETTINGS_FIELD.PROJECT_UPDATE_EVERY,
                        value,
                        INPUT_TYPES.DROPDOWN,
                        CANVAS_SETTINGS_PROPERTY.sendProjectUpdateEvery
                      )
                    }
                    options={daysList}
                  />
                </DropdownContainer>

                <DropdownContainer
                  className={`mr-2 month-select ${isViewOnlyFrontline ? 'read-only-frontline' : ''} ${canvas.emailFrequencyValue !== 1 ? 'is-plural' : ''
                    }`}
                >
                  <Select
                    value={dropdownValues.sendProjectUpdateFrequency}
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder=""
                    isDisabled={isDisabled}
                    onChange={(value) =>
                      saveCanvasSettings(
                        CANVAS_SETTINGS_FIELD.PROJECT_UPDATE_FREQUENCY,
                        value,
                        INPUT_TYPES.DROPDOWN,
                        CANVAS_SETTINGS_PROPERTY.sendProjectUpdateFrequency
                      )
                    }
                    options={pluralizedFrequency}
                  />
                </DropdownContainer>
              </div>
            </>
          )}
        </div>
      );
    }

    if (fieldName === PAST_DUE_STEP) {
      return (
        <div>
          <div className="mb-3 d-flex justify-content-between">
            <div className="d-flex">
              {name}
              {renderInfoTextBox(info)}
            </div>

            {canEdit ? (
              <Form.Check
                type="switch"
                id={fieldName}
                name={fieldName}
                checked={emailSettings.pastDueStep}
                onChange={(e) =>
                  saveCanvasSettings(fieldName, e.target.checked, INPUT_TYPES.SWITCH, property)
                }
              />
            ) : (
              renderOnOff(fieldName)
            )}
          </div>

          {emailSettings.pastDueStep && (
            <>
              <div className="d-flex align-items-center mb-3 justify-content-end">
                <Label
                  isDisabled={isDisabled}
                  className={`mr-2 ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  Send for
                </Label>
                <DropdownContainer
                  className={`mr-2 common-select ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  <Select
                    value={dropdownValues.sendPastDueEmailFor}
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder=""
                    isDisabled={isDisabled}
                    onChange={(value) =>
                      saveCanvasSettings(
                        CANVAS_SETTINGS_FIELD.PAST_DUE_FOR,
                        value,
                        INPUT_TYPES.DROPDOWN,
                        CANVAS_SETTINGS_PROPERTY.sendPastDueEmailFor
                      )
                    }
                    options={sendForList}
                  />
                </DropdownContainer>

                <Label
                  isDisabled={isDisabled}
                  className={`mr-2 ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  to
                </Label>
                <DropdownContainer
                  className={`mr-2 common-select ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  <Select
                    value={dropdownValues.sendPastDueEmailTo}
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder=""
                    isDisabled={isDisabled}
                    onChange={(value) =>
                      saveCanvasSettings(
                        CANVAS_SETTINGS_FIELD.PAST_DUE_TO,
                        value,
                        INPUT_TYPES.DROPDOWN,
                        CANVAS_SETTINGS_PROPERTY.sendPastDueEmailTo
                      )
                    }
                    options={formattedStepList}
                    isOptionDisabled={(option) => option.isDisabled}
                  />
                </DropdownContainer>
              </div>
            </>
          )}
        </div>
      );
    }

    if (commonSettings.includes(fieldName)) {
      return (
        <div className="text-right">
          <div className="mb-3">
            {canEdit ? (
              <Form.Check
                type="switch"
                id={fieldName}
                name={fieldName}
                checked={emailSettings[fieldName]}
                onChange={(e) =>
                  saveCanvasSettings(fieldName, e.target.checked, INPUT_TYPES.SWITCH, property)
                }
              />
            ) : (
              renderOnOff(fieldName)
            )}
          </div>
          {emailSettings[fieldName] && (
            <>
              <div className="d-flex align-items-center mb-3">
                <Label
                  isDisabled={isDisabled}
                  className={`mr-3 ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  Send to
                </Label>
                <DropdownContainer
                  className={`mr-2 common-select ${isViewOnlyFrontline ? 'read-only-frontline' : ''}`}
                >
                  <Select
                    value={dropdownValues[fieldName]}
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder=""
                    isDisabled={isDisabled}
                    onChange={(value) =>
                      saveCanvasSettings(
                        fieldName,
                        value,
                        INPUT_TYPES.DROPDOWN,
                        `emailSettings/${fieldName}/to`
                      )
                    }
                    options={projectList}
                  />
                </DropdownContainer>
              </div>
            </>
          )}
        </div>
      );
    }

    if (!canEdit || (fieldName === DYNAMIC_TIMELINE && !isTemplate)) return renderOnOff(fieldName, false);

    return (
      <Form.Check
        type="switch"
        id={fieldName}
        name={fieldName}
        checked={fieldName === LIVE_STATUS ? canvas.canvasStatus === LIVE : canvas[fieldName]}
        onChange={(e) => {
          if (fieldName === LIVE_STATUS) {
            toggleCanvasStatus();
          } else {
            canvas[fieldName] = e.target.checked;
            saveCanvasSettings(fieldName, e.target.checked, INPUT_TYPES.SWITCH);
          }
        }}
      />
    );
  };

  const renderItems = (items) =>
    items && !!items.length
      ? items.map(({ name, fieldName, info, property }, index) => {
        const isSendEmailToggledOn = fieldName === EMAIL_FREQUENCY_VALUE && !canvas.sendEmail;
        const isNotLastItem = index + 1 < items.length;

        return isSendEmailToggledOn ? null : (
          <Row className="m-0" key={`canvas-setting-item-${index}`}>
            <Col
              className={`${fieldName !== PAST_DUE_STEP ? 'switch-row' : ''} px-0 pt-2 ${isNotLastItem ? 'border-bottom' : 'mb-3'
                }`}
              xs={12}
            >
              {fieldName !== PAST_DUE_STEP && (
                <div className="d-inline-flex">
                  {name}
                  {renderInfoTextBox(info)}
                </div>
              )}

              {renderForm({ fieldName, name, info, property })}
            </Col>
          </Row>
        );
      })
      : null;

  const renderMenuItems = (menuItems) => (
    <>
      {menuItems && menuItems.length
        ? menuItems.map(({ header, items }, index) => (
          <Fragment key={`canvas-setting-menu-item-${index}`}>
            <Row className="modal-section-header mx-0 mb-1">
              <Col className="switch-row p-0" xs={12}>
                {header}
              </Col>
            </Row>

            {header === SYSTEM_EMAILS && (
              <Row className="mx-0 my-2">
                View and edit templates for these emails in{' '}
                <Link className="font-weight-bold d-inline-flex ml-1" to="/settings#email">
                  Settings
                </Link>
              </Row>
            )}

            {renderItems(items)}
          </Fragment>
        ))
        : null}
    </>
  );

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      wrapperClassName="d-block"
      innerclassname="p-0 m-4-5 mw-100 w-auto"
      content={
        <>
          <div className="d-flex justify-content-between align-items-center">
            <SettingHeader>
              <div className="mr-2-5">
                <FontAwesomeIcon icon={faCog} />
              </div>
              {isTemplate ? TEMPLATE_SETTINGS : PROJECT_SETTINGS}
            </SettingHeader>
            <FontAwesomeIcon icon={faTimes} onClick={onHide} className="clickable fa-xl mr-2-5" />
          </div>

          <div className="mb-3 d-flex justify-content-between align-items-center">
            <h1 className="mt-3 mb-4 mr-4 d-flex">{isTemplate ? canvas.name : canvas.canvasTemplateName}</h1>
            {isTemplate && (
              <div className="d-flex">
                <SettingHeader className="mr-2-5">Live</SettingHeader>
                {renderForm({ fieldName: LIVE_STATUS })}
              </div>
            )}
          </div>

          <div>{renderMenuItems(menuItems)}</div>
        </>
      }
    />
  );
}
