import React, { Fragment, useEffect, useRef, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import * as Api from '../../api/Api';
import ModalNewCanvas from '../../components/Modals/NewCanvas';
import PageHeader from '../../components/PageHeader';
import canvasIcon from '../../img/canvas_icon.svg';
import { NoDataTextStyle, TableDataStyle, TableStyle } from '../reporting/SharedStyles';
import TableHeader from '../reporting/TableHeader';
import { sortByDate, sortByLetter } from '../reporting/sorting';
import { TableBodyRow, TableHeaderRow, StyledImg } from './Styles';
import { FormikContext, useFormik } from 'formik';
import { noop, pluralize } from 'helpers/CommonHelper';
import { FormSwitch } from 'components/Formik/FormSwitch';
import { CanvasSettings } from 'models';
import toast from 'components/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import ModalConfirm from 'components/Modals/Confirm';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

function Canvases(props) {
  const table = useRef();

  const [loading, setLoading] = useState(false);
  const [canvases, setCanvases] = useState(null);
  const [canvasForDelete, setCanvasForDelete] = useState(null);
  const [sortedColumn, setSortedColumn] = useState('');
  const [showNewCanvasModal, setShowNewCanvasModal] = useState(false);
  const [newCanvasModalLoaded, setNewCanvasModalLoaded] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    loadCanvases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadCanvases() {
    setLoading(true);
    const response = await Api.get('Canvas/GetAll');
    setLoading(false);

    setCanvases({ ...response, items: response.items.sort((a, b) => a.name.localeCompare(b.name)) });
  }

  const handleCreateCanvas = async (name, templateId, canvasValues) => {
    const { dropdownValues, emailSettings } = canvasValues;

    const data = {
      name,
      templateId,
      autoadvance: emailSettings.autoAdvance,
      assignOwnerToSteps: emailSettings.assignOwnerToSteps,
      dynamicTimeline: emailSettings.dynamicTimeline,
      externalsHaveTaskItemPermissions: emailSettings.externalsHaveTaskItemPermissions,
      sendEmail: emailSettings.projectUpdate,
      sendPastDueEmail: emailSettings.pastDueStep,
      emailFrequencyUnit: dropdownValues.sendProjectUpdateFrequency?.value,
      emailFrequencyValue: dropdownValues.sendProjectUpdateEvery?.value,
      emailSettings: {
        projectUpdate: {
          to: dropdownValues.sendProjectUpdateTo?.value,
          frequencyUnit: dropdownValues.sendProjectUpdateFrequency?.value,
          frequencyValue: dropdownValues.sendProjectUpdateEvery?.value,
          isEnabled: emailSettings.projectUpdate,
        },
        pastDueStep: {
          for: dropdownValues.sendPastDueEmailFor?.value,
          to: dropdownValues.sendPastDueEmailTo?.value,
          isEnabled: emailSettings.pastDueStep,
        },
        invitedToProject: {
          to: dropdownValues.invitedToProject?.value,
          isEnabled: emailSettings.invitedToProject,
        },
        assignedToStepOrTask: {
          to: dropdownValues.assignedToStepOrTask?.value,
          isEnabled: emailSettings.assignedToStepOrTask,
        },
        comments: {
          to: dropdownValues.comments?.value,
          isEnabled: emailSettings.comments,
        },
      },
    };

    const newCanvas = await Api.post('Canvas/CreateCanvas', data);
    const { name: newName } = newCanvas;
    if (newName === undefined) {
      return newCanvas;
    } else {
      history(`/templates/${newCanvas.id}`, { name: newCanvas.name });
    }
  };

  const handleShowNewCanvasModal = () => {
    setShowNewCanvasModal(true);
    setNewCanvasModalLoaded(true);
  };

  const newCanvasModalOnAdd = async (name, templateId, canvasValues) => {
    setShowNewCanvasModal(false);

    const response = await handleCreateCanvas(name, templateId, canvasValues);
    if (response !== undefined) {
      setShowNewCanvasModal(true);
      return response;
    }
  };

  const newCanvasModalOnHide = () => {
    setShowNewCanvasModal(false);
  };

  const deleteCanvas = async () => {
    const canvasToDelete = canvasForDelete;
    canvasToDelete.deleting = true;
    setCanvasForDelete(null);
    await Api.post(`Canvas/DeleteCanvas?canvasId=${canvasToDelete.id}`, { props });
    canvasToDelete.deleting = false;
    loadCanvases();
  };

  const initialValues = useMemo(
    () =>
      canvases && canvases?.items && !!canvases?.items?.length
        ? canvases.items.map((item) => ({
          isLive: item.canvasStatus === 'Live',
        }))
        : [],
    [canvases]
  );

  const formikBag = useFormik({
    initialValues: { templates: initialValues },
    enableReinitialize: true,
    onSubmit: noop,
  });

  const handleSwitchChange = (item, index) => async (value) => {
    if (!!value) {
      await CanvasSettings.publish(item.id);
      return;
    }

    const response = await CanvasSettings.unpublish(item.id);
    const formattedResponse = await response.json();

    if (typeof formattedResponse === 'string') {
      toast.error(
        'This template is in use for one or more active projects and cannot be set to draft status.'
      );

      const updatedTemplates = formikBag.values.templates.map((item, idx) => {
        if (index === idx) {
          return {
            isLive: true,
          };
        }

        return item;
      });

      formikBag.setFieldValue('templates', updatedTemplates);
      return;
    }
  };

  const headerData = [
    {
      flexBasis: 30,
      sortByFunction: sortByLetter,
      sortByDefault: true,
      text: 'Name',
    },
    {
      flexBasis: 5,
      text: 'Status',
    },
    {
      flexBasis: 5,
      sortByFunction: sortByDate,
      text: 'Last Edited',
    },
    {
      flexBasis: 5,
      sortByFunction: sortByDate,
      text: 'Created',
    },
    {
      flexBasis: 5,
      text: <Fragment />,
    },
  ];

  const bodyData = (item, index) => {
    return [
      {
        flexBasis: 30,
        content: (
          <div className="canvas-content-wrapper">
            <div className={`mr-3 canvas-icon ${item.color}`}>
              <StyledImg src={canvasIcon} alt="" />
            </div>
            <div>
              <div className="bold">{item.name}</div>
              <div className="tags-days-wrapper">
                {item.days > 0 ? <div className="days-subheader">{item.days} business days</div> : null}
                {item.steps > 0 && (
                  <div className="days-subheader ml-1">| {pluralize(item.steps, 'step')}</div>
                )}
              </div>
            </div>
          </div>
        ),
      },
      {
        flexBasis: 5,
        content: (
          <div className="d-flex">
            <span className="bold mr-2">Live</span>
            <FormSwitch name={`templates[${index}].isLive`} handleChange={handleSwitchChange(item, index)} />
          </div>
        ),
      },
      {
        flexBasis: 5,
        content: item?.dateModified ? format(new Date(item?.dateModified), 'MMM d, yyyy') : '--',
      },
      {
        flexBasis: 5,
        content: item?.dateCreated ? format(new Date(item?.dateCreated), ' MMM d, yyyy') : '--',
      },
      {
        flexBasis: 5,
        alignText: true,
        content: (
          <FontAwesomeIcon
            icon={faTrash}
            spin={item.deleting}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (item.deleting) {
                return;
              }
              setCanvasForDelete(item);
            }}
          />
        ),
      },
    ];
  };

  return (
    <>
      <PageHeader title="Templates" loading={loading} showOrgName>
        <Button onClick={handleShowNewCanvasModal} variant="primary">
          New template
        </Button>
        {(newCanvasModalLoaded || showNewCanvasModal) && (
          <ModalNewCanvas
            show={showNewCanvasModal}
            onAdd={newCanvasModalOnAdd}
            onHide={newCanvasModalOnHide}
          />
        )}
      </PageHeader>
      <ModalConfirm
        title="Delete template"
        message={
          <>
            Are you sure you want to delete {!!canvasForDelete && canvasForDelete?.name}?
            <br />
            This action cannot be undone.
          </>
        }
        show={canvasForDelete !== null}
        onConfirm={() => deleteCanvas()}
        onHide={() => setCanvasForDelete(null)}
      />

      <FormikContext.Provider value={formikBag}>
        {!loading && (
          <TableStyle ref={table} type="secondary">
            <thead>
              <TableHeaderRow>
                {headerData.map((item, index) => (
                  <TableHeader
                    key={index}
                    sortedColumn={sortedColumn}
                    table={table}
                    flexBasis={item.flexBasis}
                    sortByFunction={item.sortByFunction}
                    sortByDefault={item.sortByDefault}
                    setSortedColumn={setSortedColumn}
                    alignText={item.alignText}
                    type="secondary"
                  >
                    {item.text}
                  </TableHeader>
                ))}
              </TableHeaderRow>
            </thead>

            <tbody>
              {canvases && canvases?.items?.length > 0 ? (
                canvases.items.map((item, outerIndex) => (
                  <TableBodyRow
                    key={outerIndex}
                    className="table-row"
                    onClick={(event) => {
                      if (event.target.closest('.custom-switch')) {
                        event.stopPropagation();
                        event.defaultPrevented();
                        return;
                      }

                      history(`/templates/${item.id}`, { name: item.name });
                    }}
                  >
                    {bodyData(item, outerIndex).map((item, innerIndex) => (
                      <TableDataStyle
                        key={innerIndex}
                        type="secondary"
                        flexBasis={item.flexBasis}
                        link={item?.link}
                        alignText={item.alignText}
                      >
                        {item.content}
                      </TableDataStyle>
                    ))}
                  </TableBodyRow>
                ))
              ) : (
                <tr>
                  <td className="w-full">
                    <NoDataTextStyle>No templates yet.</NoDataTextStyle>
                  </td>
                </tr>
              )}
            </tbody>
          </TableStyle>
        )}
      </FormikContext.Provider>
    </>
  );
}

export default Canvases;
