import React, { useState, useMemo, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Droppable } from 'react-beautiful-dnd';
import DateChangeState from '../Modals/Shared/DateChange/context/dateChangeState';
import EditCard from '../BuilderCardColumns/EditCard';
import ModalConfirm from 'components/Modals/Confirm/index';
import { CircleIcon, Content } from './Styles';
import InnerList from './InnerList';
import DeleteStepConfirmation from './DeleteStep';
import FullContext from 'stores/Full/fullContext';
import DeleteButtonTrashIcon from 'components/DeleteButtonTrashIcon';

const CardColumn = ({ cardState, canvasState, canvasHandlers, onEdit, reloadCanvas }) => {
  const [itemForDelete, setItemForDelete] = useState(null);
  const [addingItem, setAddingNewItem] = useState(false);
  const [promptDeletion, setPromptDeletion] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { isEditMode } = useContext(FullContext);

  const { id, title, emptyString, items, draggedStep } = cardState;
  const { showMyTouchPoints, canvasStartDate, canvasEndDate, mergedSteps, assigningToMember } = canvasState;

  let onAddTouchpoint;
  let onDeleteTouchpoint;
  let onDuplicateTouchpoint;
  let onDeletePhase;

  if (canvasHandlers?.onAddTouchpoint) ({ onAddTouchpoint } = canvasHandlers);

  if (canvasHandlers?.onDeleteTouchpoint) ({ onDeleteTouchpoint } = canvasHandlers);

  if (canvasHandlers?.onDuplicateTouchpoint) ({ onDuplicateTouchpoint } = canvasHandlers);

  if (canvasHandlers?.onDeletePhase) ({ onDeletePhase } = canvasHandlers);

  const onItemAdd = async (name) => {
    if (onAddTouchpoint) await onAddTouchpoint(id, name);
    setAddingNewItem(false);
  };

  const filteredItems = items.filter((value) => (showMyTouchPoints ? value.isMember : value));

  const hasFilteredItems = filteredItems.length > 0;

  const step = useMemo(
    () => filteredItems.find((item) => item.id === itemForDelete),
    [filteredItems, itemForDelete]
  );

  const onItemDelete = async (id, callbacks) => {
    if (callbacks) {
      callbacks.push(() => setItemForDelete(null));
    } else {
      callbacks = [() => setItemForDelete(null)];
    }
    if (onDeleteTouchpoint) await onDeleteTouchpoint(id, callbacks);
  };

  const onCardDelete = (id) => {
    setItemForDelete(id);
  };

  const displayAddStep = () =>
    !addingItem ? (
      <div className="card-wrapper">
        <CircleIcon className="circle-icon" />
        <Button onClick={() => setAddingNewItem(true)} className="card-width" variant="light">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
    ) : (
      <EditCard type={'step'} onItemAdd={onItemAdd} />
    );

  const confirmDeleteJourney = async () => {
    if (deleting) {
      return;
    }
    setPromptDeletion(true);
  };

  const deletePhase = async () => {
    setDeleting(true);
    if (onDeletePhase) await onDeletePhase(id);
    setDeleting(false);
    setPromptDeletion(false);
  };

  if (hasFilteredItems)
    return (
      <div className="column">
        <div className="card-column-header">
          <h2 className="title">{title}</h2>
          {isEditMode && (<DeleteButtonTrashIcon onClick={() => confirmDeleteJourney()} />)}
        </div>

        <Droppable droppableId={`journey-${id}`} direction="vertical" type="touchpoint">
          {(provided) => (
            <Content {...provided.droppableProps} ref={provided.innerRef}>
              <InnerList
                reloadCanvas={reloadCanvas}
                items={filteredItems}
                onEdit={onEdit}
                onDelete={onCardDelete}
                onDuplicate={onDuplicateTouchpoint}
                draggedStep={draggedStep}
                assigningToMember={assigningToMember}
              />
              {provided.placeholder}
            </Content>
          )}
        </Droppable>

        <ModalConfirm
          title="Delete phase"
          message={
            <>
              Are you sure you want to delete this phase?
              <br />
              This action cannot be undone.
            </>
          }
          show={promptDeletion}
          onConfirm={deletePhase}
          onHide={() => {
            setPromptDeletion(false);
          }}
          confirmLoading={deleting}
          resetOnConfirm={true}
        />

        {isEditMode && displayAddStep()}

        {isEditMode && step && (
          <DateChangeState touchpoint={step} canvasStartDate={canvasStartDate} canvasEndDate={canvasEndDate}>
            <DeleteStepConfirmation
              itemForDelete={itemForDelete}
              onItemDelete={onItemDelete}
              setItemForDelete={setItemForDelete}
              mergedSteps={mergedSteps}
            />
          </DateChangeState>
        )}
      </div>
    );

  return (
    <div className="column" data-testid={title}>
      <div className="card-column-header">
        <h2 className="title">{title}</h2>
      </div>

      <Droppable droppableId={`journey-${id}`} direction="vertical" type="touchpoint">
        {(provided) => (
          <Content {...provided.droppableProps} ref={provided.innerRef}>
            <div className="card-column-header small-info">{emptyString}</div>
            {provided.placeholder}
          </Content>
        )}
      </Droppable>

      {isEditMode && displayAddStep()}
    </div>
  );
};

export default CardColumn;
