import { faEllipsisH, faTrash, faCog, faPause, faPen, faClock } from '@fortawesome/pro-solid-svg-icons';
import { faUserFriends } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Button, Dropdown, ProgressBar, Container, Row, Col, Form } from 'react-bootstrap';
import * as Api from 'api/Api';
import CanvasCardColumns from 'components/CanvasCardColumns/CardColumns';
import LoadingInline from 'components/Loading/LoadingInline';
import Members from 'components/Members';
import PauseCanvas from 'components/Modals/PauseCanvas';
import ModalConfirm from 'components/Modals/Confirm/index';
import ModalTouchpoint from 'components/Modals/Touchpoint/Touchpoint';
import ModalCanvasSettings from 'components/Modals/CanvasSettings/CanvasSettings';
import PageHeader from 'components/PageHeader';
import toast from 'components/toast';
import * as CommonHelper from 'helpers/CommonHelper';
import MyTouchpointToggle from 'components/Modals/MyTouchpointToggle';
import { debounce, concatStringsOn } from 'helpers/CommonHelper';
import CustomerContext from 'stores/Customer/customerContext';
import AlertDialog from 'components/Modals/Shared/AlertDialog';
import {
  Touchpoint,
  CustomerCanvas as CustomerCanvasModel,
  CanvasSettings as CanvasSettingsModel,
  Settings,
} from 'models';
import {
  DEFAULT,
  EDIT,
  VIEW_AS_CUSTOMER,
  PAUSED,
  IN_PROGRESS,
  COMPLETED,
  FRONT_LINE,
  NAME,
  UNTITLED_PROJECT,
  SETTINGS_PATH,
  PROJECT_LIST,
} from 'constants';
import FullContext from 'stores/Full/fullContext';
import format from 'date-fns/format';
import { AssignModalContainer, MoreInfoText, TitleContainer } from './Styles';
import Tags from 'components/Modals/Touchpoint/AddMembers/Tags';
import Checkbox from 'components/Checkbox/Checkbox';
import { EmptyTagsContainer } from 'components/Modals/Touchpoint/AddMembers/Styles';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import MoreInfoContainer from './MoreInfoContainer';
import { withRouter } from 'helpers/RouteHelper';

class CustomerCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeCanvasSettingParam = this.handleChangeCanvasSettingParam.bind(this);
  }
  static contextType = CustomerContext;

  state = {
    loading: true,
    editingName: false,
    error: null,
    members: null,
    customer: null,
    journeys: null,
    customerComments: [],
    startDate: null,
    endDate: null,
    projectedDate: null,
    showDueDates: false,
    showingTouchpoint: null,
    assigningToMember: null,
    massAssignModel: null,
    savingAssignments: false,
    showEditMembers: false,
    deletingCanvas: false,
    showingCanvasChangedModal: false,
    showMyTouchpoints: false,
    currentUserAssignments: {},
    pausingCanvas: false,
    pausingCanvasNote: '',
    canvasMembersById: {},
    dependentData: [],
    showUnchangedStatusModal: false,
    userData: null,
    isFrontlineEnabledToEdit: false,
    isExternalInviteEnabled: false,
    canvasTags: [],
    selectedTags: [],
    isAddToAllSteps: false,
    showMoreInfo: false,
    canvas: null,
  };

  async componentDidMount() {
    await this.initPathVariables();
    await this.loadCustomer();
    await this.loadPermission();
    await this.projectEngagement();
    await this.readDueDates();
    await this.loadJourneys();
    await this.loadMembers(this.state.userData?.email);
    if (this.props.router.location.pathname === SETTINGS_PATH) this.onShowSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.router.params.touchpointId &&
      !prevProps.router.params.touchpointId &&
      prevProps.router.params.touchpointId !== this.props.router.params.touchpointId
    ) {
      this.showTouchpointModal();
    }

    if (prevProps.router.params.touchpointId && !this.props.router.params.touchpointId) {
      this.setState({ showingTouchpoint: null });
    }

    if (this.state.members !== prevState.members) {
      this.setState({
        currentUserAssignments: this.state.members
          .find((u) => u.id === this.props?.currentUserId)
          ?.customerTouchpointAssigned.reduce((res, cur) => {
            res[cur.customerTouchpointId] = cur.assigned;
            return res;
          }, {}),
      }, () => this.showTouchpointModal());
    }

    if (this.props.isCanvasUpdated !== prevProps.isCanvasUpdated) {
      this.loadJourneys();
      this.props.setCanvasState(this.props.isCanvasUpdated);
    }
  }

  componentWillUnmount() {
    this.context.populateExternalData({});
    this.context.setActiveCanvasMode(DEFAULT);
  }

  getTouchpointId() {
    return parseInt(this.props.router.params.touchpointId);
  }

  initPathVariables() {
    this.customerId = parseInt(this.props.router.params.id);
    this.customerCanvasId = parseInt(this.props.router.params.customerCanvasId);
  }

  loadCustomer = async () => {
    const customer = await CustomerCanvasModel.getByCustomerAndCustomerCanvasId(
      this.customerId,
      this.customerCanvasId
    );

    const canvasTags = (await CustomerCanvasModel.getCanvasTags(this.customerCanvasId)) || [];

    this.setState({
      canvas: customer.canvas,
    });
    if (customer.canvas) {
      this.setState({ customer, canvasTags });
    } else {
      this.setState({ error: 'This canvas was not found.', loading: false });
    }
  };

  loadPermission = async () => {
    const permissions = await Settings.getGeneralConfig();
    const foundPermission = permissions.find(({ name }) => name === 'canEditProject');
    const canEditProject = !!foundPermission.value;

    this.setState({
      isFrontlineEnabledToEdit: canEditProject,
    });
  };

  projectEngagement = () => {
    const AccountId = this.props.router.params.id;
    const ProjectId = this.props.router.params.customerCanvasId;

    return Api.post('Project/ProjectUserEngagement', {
      AccountId,
      ProjectId,
    });
  };

  async loadJourneys() {
    const data = await Api.get('Journey/GetByCustomerCanvas', {
      customerCanvasId: this.customerCanvasId,
      customerId: this.customerId,
    });

    data.items = data.items.map((item) => ({
      ...item,
      touchpoints: item.touchpoints.map((_touchpoint) => new Touchpoint(_touchpoint)),
    }));

    this.setState({ journeys: data.items, loading: false }, () => this.showTouchpointModal());
    const dependentSteps = data?.items.map((journey) => {
      return { name: journey.name, touchpoints: [...journey.touchpoints] };
    });

    this.setState({
      dependentData: [...dependentSteps],
      startDate: data.startDate ? format(new Date(data?.startDate), 'MMM d, yyyy') : null,
      endDate: data.endDate ? format(new Date(data?.endDate), 'MMM d, yyyy') : null,
      projectedDate: data.projectedDate ? format(new Date(data?.projectedDate), 'MMM d, yyyy') : null,
    });

    const { projectSettings } = data;
    this.setState((prev) => {
      return {
        canvas: {
          ...prev.canvas,
          ...projectSettings,
        },
        isExternalInviteEnabled:
          projectSettings.emailSettings.invitedToProject.isEnabled &&
          (projectSettings.emailSettings.invitedToProject.to === PROJECT_LIST.EXTERNAL ||
            projectSettings.emailSettings.invitedToProject.to === PROJECT_LIST.ALL_MEMBERS),
      };
    });
  }

  readDueDates() {
    const {
      state: { canvas, customer },
    } = this;
    if (customer?.customerTouchpoints && customer?.customerTouchpoints.length) {
      this.setState({
        complete: canvas.progress,
        showDueDates: canvas.endDate && canvas.projectedDate,
      });
    }
  }

  loadCustomerComments = async (touchpoint) => {
    const response = await Api.get('Customer/GetComments', {
      customerTouchpointId: touchpoint?.id ?? this.state.showingTouchpoint.id,
      page: 1,
      size: 50,
    });
    this.setState({ customerComments: response.items });
  };

  setNewComments = (comments) => {
    this.setState({ customerComments: comments });
  };

  handleChangeCanvasName = (e) => {
    this.setState(
      {
        canvas: {
          ...this.state.canvas,
          canvasName: e.target.value,
        },
      },
      () => this.editCustomerCanvas()
    );
  };

  editCustomerCanvas = debounce(async () => {
    var name = this.state.canvas.canvasName;

    const newName = await CanvasSettingsModel.changeCustomerCanvasSettingParam(
      NAME,
      name,
      this.customerCanvasId
    );
    this.setState((prev) => {
      return {
        canvas: {
          ...prev.canvas,
          canvasName: newName,
        },
      };
    });
  }, 1000);

  pauseCanvas = async () => {
    await Api.post('Canvas/PauseCanvas', {
      customerCanvasId: this.customerCanvasId,
      note: this.state.pausingCanvasNote,
    });

    this.togglePauseCanvas();
    this.reloadCanvasTouchpoints();
  };

  unPauseCanvas = async () => {
    await Api.post('Canvas/UnPauseCanvas', {
      customerCanvasId: this.customerCanvasId,
    });

    this.reloadCanvasTouchpoints();
  };

  createNewCustomerTouchpointsDialogToggle() {
    this.setState({ createNewCustomerTouchpointsDialog: !this.state.createNewCustomerTouchpointsDialog });
  }

  showTouchpointModal() {
    const touchpointId = this.getTouchpointId();
    if (touchpointId && this.state.members && this.state.journeys) {
      const params = new URLSearchParams(this.props.router.location.search);

      if (params.get('success')) {
        toast.saved(params.get('success'));
      }

      for (const journey of this.state.journeys) {
        const touchpoint = journey.touchpoints.find((item) => item.id === touchpointId);

        if (!touchpoint) {
          // See ACD-114. The backend change pushed on 10/30/2021 changed how the URLs needed to
          // be created. Previously it would use the template touchpoint ID (for what reason, I have
          // no idea) and now needs to use the CustomerTouchpoint ID. For that reason the previous find
          // step will likely come up empty for links created before 10/30/2021. This is the fallback
          // to lookup the template touchpoint id.
          const templateTouchpoint = journey.touchpoints.find(
            (item) => item.templateTouchpointId === touchpointId
          );
          if (templateTouchpoint) {
            this.props.router.navigate(
              `/accounts/${this.customerId}/projects/${this.customerCanvasId}/steps/${templateTouchpoint.id}`
            );
          }
        }

        if (touchpoint) {
          if (this.props.isExternal && !this.currentUserIsMemberOf(touchpoint?.id)) {
            // User is an External user and is not a member of this step
            return;
          }
          this.loadCustomerComments(touchpoint);
          this.setState({ showingTouchpoint: { ...touchpoint } }, () => this.forceUpdate());
          break;
        }
      }
    }
  }

  toggleShowMyTouchpoints = () => {
    this.setState({ showMyTouchpoints: this.state.showMyTouchpoints });
  };

  touchpointModalOnHide = () => {
    this.props.router.navigate(`/accounts/${this.customerId}/projects/${this.customerCanvasId}`);
    this.setState({ showingTouchpoint: null });
  };

  updateCustomerTouchpointStatus(customerTouchpointId, status) {
    const customerTouchpoint = this.state.customer.customerTouchpoints.find(
      (x) => x.id === customerTouchpointId
    );
    if (customerTouchpoint) {
      customerTouchpoint.status = status;
      this.setState({});
    }
  }

  setCustomerTouchpointStatus = async (status) => {
    const touchpoint = new Touchpoint(this.state.showingTouchpoint);
    const oldStatus = touchpoint.status;
    touchpoint.status = status.value;
    this.setState({});

    if (touchpoint.customerTouchpointStatus === COMPLETED && touchpoint.hasTask && !touchpoint.isComplete) {
      this.setState({ showUnchangedStatusModal: true });
    }
    const response = await CustomerCanvasModel.setCustomerTouchpointStatus(
      status.value,
      this.state.showingTouchpoint.id
    );
    if (response.errorMessage) {
      toast.error(response.errorMessage);
      touchpoint.status = oldStatus;
      this.setState({});
    } else {
      if (response && response.customerTouchpointId) {
        this.updateCustomerTouchpointStatus(response.customerTouchpointId, response.status);
      }
      this.readDueDates();
    }
    await this.reloadCanvasTouchpoints();
  };

  onChecklistUpdate = (updatedStatuses) => {
    // the server returns a list of statuses that were updated due to the checklist items updating
    let loadCustomer = false;
    if (updatedStatuses) {
      updatedStatuses.forEach((item) => {
        this.updateCustomerTouchpointStatus(item.customerTouchpointId, item.status);
        loadCustomer = true;
      });
    }
    if (loadCustomer) {
      const { showingTouchpoint } = this.state;
      const touchpoint = new Touchpoint(showingTouchpoint);
      if (touchpoint.hasTask && touchpoint.isNotStarted) {
        const statuses = CommonHelper.getCustomerTouchpointStatusList();
        const newStatus = touchpoint.hasIncompleteTask ? IN_PROGRESS : COMPLETED;
        const newStatusObj = statuses.find(({ value }) => value === newStatus);

        this.setCustomerTouchpointStatus(newStatusObj);
      }
      this.reloadCanvasTouchpoints();
    }
  };

  handleFilesUpdated = (attachments, links) => {
    const touchpoint = this.state.showingTouchpoint;
    touchpoint.attachments = attachments;
    touchpoint.links = links;
    this.setState({ showingTouchpoint: { ...touchpoint } });
  };

  handleResourcesUpdated = (attachments, links) => {
    const touchpoint = this.state.showingTouchpoint;
    touchpoint.touchpointAttachments = attachments;
    touchpoint.touchpointLinks = links;
    this.setState({ showingTouchpoint: { ...touchpoint } });
  };

  handleIdeasUpdated = (ideas) => {
    const touchpoint = this.state.showingTouchpoint;
    touchpoint.touchpointIdeas = ideas;
    this.setState({});
  };

  handlePauseCanvasNoteUpdates = (e) => {
    this.setState({ pausingCanvasNote: e.target.value });
  };

  async loadMembers(email) {
    const response = await Api.get('Customer/GetCustomerCanvasAssignedUsers', {
      customerCanvasId: this.customerCanvasId,
    });

    if (this.props.isExternal && response.items.find((item) => item.email === email) === -1) {
      window.location.replace('/404');
    }

    const canvasMembersById = response.items.reduce((res, cur) => {
      res[cur.id] = cur;
      return res;
    }, {});

    this.setState({ members: response.items, canvasMembersById }, () => this.showTouchpointModal());
  }

  addMembers = async (addMembersModel, email) => {
    await Api.post('User/AddUserToCustomerCanvas', addMembersModel, {
      params: { customerCanvasId: this.customerCanvasId },
    });
    await this.loadMembers(email);
  };

  removeMember = async (member, email) => {
    await Api.post('User/RemoveUserFromCustomerCanvas', null, {
      params: { customerCanvasId: this.customerCanvasId, userId: member.id },
    });
    await this.loadCustomer(email);
    await this.loadMembers(email);
  };

  toggleAssignment(memberIds) {
    for (const member of this.state.members) {
      if (memberIds.some((x) => x === member.id)) {
        for (const assignedObj of member.customerTouchpointAssigned) {
          if (assignedObj.customerTouchpointId === this.state.showingTouchpoint.id) {
            assignedObj.assigned = true;
          } else {
            assignedObj.assigned = false;
          }
        }
      }
    }
    this.setState({ members: [...this.state.members] });
  }

  addMembersTouchpoint = async (addMembersModel, email) => {
    await CustomerCanvasModel.addUsersToCustomerTouchpoint(addMembersModel, this.state.showingTouchpoint.id);
    await this.loadMembers(email);
  };

  addMembersTaskItem = async (taskItemId, addMembersModel, email) => {
    const res = await Api.post('Customer/AddUsersToCustomerTouchpointTaskItem', addMembersModel, {
      params: { customerTouchpointId: this.state.showingTouchpoint.id, taskItemId },
    });
    this.toggleAssignment(addMembersModel.userIds);
    const customerTouchpoint = this.state.showingTouchpoint;
    customerTouchpoint.tasks = customerTouchpoint.tasks.map((task) => {
      if (task.id === taskItemId) {
        return {
          ...task,
          memberIds: res.memberIds,
        };
      }
      return task;
    });
    this.setState({ showingTouchpoint: { ...customerTouchpoint } });
    await this.loadMembers(email);
    return res;
  };

  removeMemberTaskItem = async (taskItemId, memberId, email) => {
    await Api.post(
      'Customer/RemoveUsersFromCustomerTouchpointTaskItem',
      { userIds: [memberId] },
      {
        params: {
          taskItemId,
          customerTouchpointId: this.state.showingTouchpoint.id,
        },
      }
    );
    this.toggleAssignment([memberId]);
    const customerTouchpoint = this.state.showingTouchpoint;
    customerTouchpoint.tasks = customerTouchpoint.tasks.map((task) => {
      if (task.id === taskItemId) {
        return {
          ...task,
          memberIds: task.memberIds.filter((id) => id !== memberId),
        };
      }
      return task;
    });
    this.setState({ showingTouchpoint: { ...customerTouchpoint } });
    await this.loadMembers(email);
  };

  removeMemberTouchpoint = async (member) => {
    await Api.post(
      'Customer/RemoveUsersFromCustomerTouchpoint',
      { userIds: [member.id] },
      {
        params: {
          customerId: this.state.showingTouchpoint.customerId,
          touchpointId: this.state.showingTouchpoint.id,
        },
      }
    );
    await this.loadMembers();
    await this.loadCustomer();
  };

  membersForTouchpoint(touchpointId) {
    const touchpointMembers = [];
    for (const member of this.state.members || []) {
      for (const assignedObj of member.customerTouchpointAssigned) {
        if (assignedObj.customerTouchpointId === touchpointId && assignedObj.assigned) {
          touchpointMembers.push(member);
        }
      }
    }
    return touchpointMembers;
  }

  startAssign = (member) => {
    if (member) {
      this.context.setActiveCanvasMode(DEFAULT);

      this.setState({
        assigningToMember: member,
        massAssignModel: member.customerTouchpointAssigned.reduce((prev, curr) => {
          prev.push({ ...curr });
          return prev;
        }, []),
      });
    }
  };

  cancelAssign = () => {
    this.setState({
      assigningToMember: null,
      savingAssignments: false,
      selectedTags: [],
      isAddToAllSteps: false,
    });
  };

  saveAssign = async () => {
    this.setState({ savingAssignments: true });
    await Api.post(
      'Customer/AddUserToCustomerTouchpoints',
      { items: this.state.massAssignModel },
      { params: { userId: this.state.assigningToMember.id } }
    );
    this.state.assigningToMember.customerTouchpointAssigned = [...this.state.massAssignModel];
    this.setState({
      assigningToMember: null,
      savingAssignments: false,
      massAssignModel: null,
      selectedTags: [],
      isAddToAllSteps: false,
    });
    toast.saved('Member assignments updated successfully.');
  };

  setShowMembersModal = (show) => {
    this.setState({ showEditMembers: show });
  };

  togglePauseCanvas = () => {
    this.setState({ pausingCanvas: !this.state.pausingCanvas });
  };

  toggleDeleteCanvasConfirm = () => {
    this.setState((prev) => ({ deletingCanvas: !prev.deletingCanvas }));
  };

  toggleCanvasChangedModal = () => {
    this.setState((prev) => ({ showingCanvasChangedModal: !prev.showingCanvasChangedModal }));
  };

  deleteCanvas = async () => {
    await Api.get('Customer/DeleteCustomerCanvas', { customerCanvasId: this.customerCanvasId });
    this.props.router.navigate('/accounts/' + this.customerId);
    toast.saved('Account project deleted successfully.');
  };

  currentUserIsMemberOf = (customerTouchpointId) => {
    return this.state.currentUserAssignments?.[customerTouchpointId] || false;
  };

  reloadCanvasTouchpoints = async () => {
    await this.loadJourneys();
    await this.loadCustomer();
    await this.loadMembers(this.state.userData?.email);
  };

  onDeletePhase = async (phaseId, callbacks) => {
    const result = await CustomerCanvasModel.deleteActiveProjectPhase(this.customerCanvasId, phaseId);

    /*AffinityCanvasException will cause an endpoint to return object with errorMessage property*/
    if (result?.errorMessage) {
      toast.error(result?.errorMessage);
    } else {
      if (callbacks) {
        callbacks.forEach((f) => f());
      }
    }
    this.reloadCanvasTouchpoints();
    toast.success('Phase deleted successfully.');
  };

  onDeleteTouchpoint = async (stepId, callbacks) => {
    const result = await Touchpoint.deleteStep(stepId);

    /*AffinityCanvasException will cause an endpoint to return object with errorMessage property*/
    if (result?.errorMessage) {
      toast.error(result?.errorMessage);
    } else {
      if (callbacks) {
        callbacks.forEach((f) => f());
      }
    }
    this.reloadCanvasTouchpoints();
  };

  onDuplicateTouchpoint = async (stepId) => {
    const result = await Touchpoint.duplicateStep(stepId);

    /*AffinityCanvasException will cause an endpoint to return object with errorMessage property*/
    if (result?.errorMessage) {
      toast.error(result?.errorMessage);
      return;
    }

    this.reloadCanvasTouchpoints();
  };

  onAddTouchpoint = async (journeyId, title) => {
    const payload = {
      CustomerCanvasId: this.customerCanvasId,
      CustomerJourneyId: journeyId,
      CustomerTouchpoint: {
        Title: CommonHelper.getUniqueName(this.mergeAllSteps(), title),
      },
    };

    await Touchpoint.addStep(payload);
    await this.reloadCanvasTouchpoints();
  };

  onReorderTouchpoints = async (stepId, order, destinationId) => {
    await Touchpoint.reorderStep(stepId, order, destinationId);
    await this.reloadCanvasTouchpoints();
  };

  mergeAllSteps = () => this.state.journeys?.map((item) => item.items).flat();

  async handleChangeCanvasSettingParam(field, value) {
    this.setState((prev) => {
      return {
        canvas: {
          ...prev.canvas,
          [field]: value,
        },
      };
    });

    await this.loadJourneys();
  }

  onShowSettings = () => {
    this.props.router.navigate(`/accounts/${this.customerId}/projects/${this.customerCanvasId}/settings`);
    this.setState({
      showCanvasSettingsModal: true,
    });
  };

  onHideSettings = () => {
    this.props.router.navigate(`/accounts/${this.customerId}/projects/${this.customerCanvasId}`);
    this.setState({
      showCanvasSettingsModal: false,
    });
  };

  handleAddTags = (selectedTagId) => {
    if (!this.state.assigningToMember) return;

    let updatedSelectedTags;

    const foundTag = this.state.selectedTags.some((item) => item === selectedTagId);

    if (foundTag) {
      updatedSelectedTags = this.state.selectedTags.filter((item) => item !== selectedTagId);
    } else {
      updatedSelectedTags = [...this.state.selectedTags, selectedTagId];
    }

    const mergedSteps = [...this.mergeAllSteps()];

    const updatedMassAssignModel = this.state.massAssignModel.map((item) => {
      const foundTouchpoint = mergedSteps.find(({ id }) => id === item.customerTouchpointId);

      const isSelected = foundTouchpoint.touchpointTags.some((touchpointTag) =>
        updatedSelectedTags.includes(+touchpointTag.tagId)
      );

      const foundAssignedTouchpoint = this.state.assigningToMember?.customerTouchpointAssigned.find(
        ({ customerTouchpointId }) => customerTouchpointId === item.customerTouchpointId
      );

      return {
        ...item,
        assigned: isSelected || foundAssignedTouchpoint.assigned,
      };
    });

    this.setState({
      selectedTags: updatedSelectedTags,
      massAssignModel: updatedMassAssignModel,
    });
  };

  toggleTouchpointForAssign = (touchpoint) => {
    touchpoint.selectedModel.assigned = !touchpoint.selectedModel.assigned;
    this.setState({ journeys: [...this.state.journeys] });
  };

  handleAssignAllSteps = (event) => {
    const value = event.target.checked;
    const isChecked = !!value;

    let massAssignModel = this.state.massAssignModel;

    if (isChecked) {
      massAssignModel = massAssignModel.map((item) => ({ ...item, assigned: true }));
    } else {
      massAssignModel = this.state.assigningToMember.customerTouchpointAssigned;
    }

    this.setState({
      isAddToAllSteps: value,
      massAssignModel,
    });
  };

  canvasDetail(email) {
    this.state.journeys.forEach((journey) => {
      journey.title = journey.name;
      journey.items = journey.touchpoints
        .sort((a, b) => a.order - b.order)
        .map((touchpoint) => {
          const customerJourney = this.state.journeys.find((x) => x.id === touchpoint.customerJourneyId);
          const customerTouchpoint = customerJourney.touchpoints.find((x) => x.id === touchpoint.id);
          touchpoint.displayDate =
            customerTouchpoint &&
            (customerTouchpoint.status === COMPLETED
              ? customerTouchpoint.dateTimeCompleted
              : customerTouchpoint.scheduledDate
                ? customerTouchpoint.scheduledDate
                : customerTouchpoint.endDate);
          touchpoint.customerTouchpointStatus = customerTouchpoint && customerTouchpoint.status;
          touchpoint.pastDue = customerTouchpoint?.pastDue;
          touchpoint.key = touchpoint.id;
          touchpoint.isMember = this.currentUserIsMemberOf(customerTouchpoint?.id);
          touchpoint.members = this.membersForTouchpoint(customerTouchpoint?.id);
          if (this.state.assigningToMember) {
            const selectedModel = this.state.massAssignModel.find(
              (x) => x.customerTouchpointId === customerTouchpoint.id
            );

            touchpoint.onSelect = this.toggleTouchpointForAssign;
            touchpoint.selectedModel = selectedModel;
          } else {
            touchpoint.onSelect = null;
            touchpoint.link = `/accounts/${this.customerId}/projects/${this.customerCanvasId}/steps/${touchpoint.id}`;
          }
          return touchpoint;
        });
    });

    const canvasState = {
      columns: this.state.journeys,
      showMyTouchPoints: this.state.showMyTouchpoints,
      canvasStartDate: this.state.canvas.startDate,
      canvasEndDate: this.state.endDate,
      mergedSteps: [...this.mergeAllSteps()],
      assigningToMember: this.state.assigningToMember,
    };

    const canvasHandlers = {
      onAddTouchpoint: this.onAddTouchpoint,
      onDeleteTouchpoint: this.onDeleteTouchpoint,
      onDuplicateTouchpoint: this.onDuplicateTouchpoint,
      onReorderTouchpoints: this.onReorderTouchpoints,
      onDeletePhase: this.onDeletePhase,
    };

    return (
      <div>
        <CanvasCardColumns
          canvasState={canvasState}
          canvasHandlers={canvasHandlers}
          reloadCanvas={this.reloadCanvasTouchpoints}
        />

        <AlertDialog visible={this.state.assigningToMember} maxWidth="49.375rem" top="8.125rem">
          <AssignModalContainer>
            <div className="header">
              <span>
                Assign{' '}
                <strong>
                  {this.state.assigningToMember?.firstName} {this.state.assigningToMember?.lastName}
                </strong>
              </span>

              <div>
                <Button variant="outline-light" onClick={this.cancelAssign} className="mr-2-5">
                  Cancel
                </Button>
                <Button onClick={this.saveAssign}>
                  {this.state.savingAssignments ? <LoadingInline white text="Saving..." /> : 'Done'}
                </Button>
              </div>
            </div>

            <div className="tags">
              <p>
                You can assign {this.state.assigningToMember?.firstName} to specific steps below, or choose
                tags to assign them to those steps or tasks.
              </p>

              {this.state.canvasTags.length > 0 ? (
                <Tags
                  isAddToProjectChecked
                  canvasTags={this.state.canvasTags}
                  handleAddTags={this.handleAddTags}
                  selectedTags={this.state.selectedTags}
                />
              ) : (
                <EmptyTagsContainer>
                  <span>No tags. Use tags for easier assignment</span>
                  <InfoTextBox notActive={false} width="30.625rem" top="-6.175rem" left="-15rem">
                    Add tags to steps in your template, for example “Customer”. When selecting the tag here,
                    the user will be added to all steps with that tag.
                  </InfoTextBox>
                </EmptyTagsContainer>
              )}
            </div>

            <div className="assign-all">
              <Checkbox
                checked={this.state.isAddToAllSteps}
                onChange={this.handleAssignAllSteps}
                label="Add to all steps"
              />
            </div>
          </AssignModalContainer>
        </AlertDialog>
      </div>
    );
  }

  isProjectedDateLate() {
    return new Date(this.state.projectedDate) > new Date(this.state.endDate);
  }

  headerDetail(isViewAsCustomer, email, roleAtleast) {
    return (
      <div className="canvas-header order-md-0">
        <div className="section">
          <h2 className="title">Project</h2>

          <div
            className={'content d-flex align-items-center toggle-edit'}
            onClick={() =>
              !this.props.isExternal && !isViewAsCustomer && this.setState({ editingName: true })
            }
          >
            {!this.state.editingName ? (
              <div className="toggle-edit-nonedit">
                {this.state.canvas &&
                  CommonHelper.trimStringWithEllipsis(this.state.canvas.canvasName || UNTITLED_PROJECT, 60)}
              </div>
            ) : (
              <Form.Control
                as="textarea"
                rows="3"
                className="no-border editable-header"
                placeholder={UNTITLED_PROJECT}
                autoFocus
                value={this.state.canvas ? this.state.canvas.canvasName || '' : '...'}
                onChange={this.handleChangeCanvasName}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.setState({ editingName: false });
                  }
                }}
                onBlur={() => this.setState({ editingName: false })}
              ></Form.Control>
            )}
          </div>
        </div>
        <div className="section">
          <Members
            title="Project Members"
            customerId={this.customerId}
            customerCanvasId={this.customerCanvasId}
            members={this.state.members}
            onAddMembers={(model) => this.addMembers(model, email)}
            onRemoveMember={(member) => this.removeMember(member, email)}
            canView={roleAtleast(FRONT_LINE)}
            canAdd={roleAtleast(FRONT_LINE)}
            showEditMembers={this.state.showEditMembers}
            onSetShowEditMembers={(show) => this.setShowMembersModal(show)}
            onStartAssign={this.startAssign}
            isExternalInviteEnabled={this.state.isExternalInviteEnabled}
            canvasTags={this.state.canvasTags}
            scope="canvas"
          />
        </div>
        {this.state.showDueDates && (
          <>
            <div className="section">
              <h2 className="title mb-2">Progress</h2>
              <div className="content">
                <span className="content-label mb-2">{this.state.canvas.progress}%</span>
                <ProgressBar now={this.state.canvas.progress} />
                <MoreInfoText
                  className="mt-2 d-block cursor-pointer"
                  onClick={() => this.setState({ showMoreInfo: true })}
                >
                  More info
                </MoreInfoText>
              </div>
            </div>

            <Container className="section">
              <Row>
                <Col>
                  {this.state.canvas.status === PAUSED && <h2 className="title text-right">Timeline</h2>}
                </Col>
                <Col>
                  <h2 className="title">
                    {this.state.canvas.status === PAUSED ? (
                      <span className="danger">{PAUSED}</span>
                    ) : (
                      'Timeline'
                    )}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="sub-title">Start</h2>
                </Col>
                <Col className="font-weight-bold">{this.state.startDate}</Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="sub-title">Forecasted End</h2>
                </Col>
                <Col className={concatStringsOn(this.isProjectedDateLate(), 'p-0', 'past-due')}>
                  {this.isProjectedDateLate() && <FontAwesomeIcon icon={faClock} className="past-due mr-2" />}
                  <span className="font-weight-bold">{this.state.projectedDate}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="sub-title">Target End</h2>
                </Col>
                <Col className="p-0 font-weight-bold">{this.state.endDate}</Col>
              </Row>
            </Container>
          </>
        )}
        {!this.state.showDueDates && this.state.canvas.status === PAUSED && (
          <>
            <div className="section">
              <h2 className="title mb-2">
                <span className="danger ml-2">{PAUSED}</span>
              </h2>
            </div>
          </>
        )}
      </div>
    );
  }

  headerOverflow(isViewAsCustomer, roleAtleast) {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <div ref={ref} onClick={onClick} className="cursor-pointer">
        {children}
      </div>
    ));

    const { setActiveCanvasMode } = this.context;
    const { isFrontLine } = this.props;

    if (!roleAtleast(FRONT_LINE) || isViewAsCustomer) return null;

    return (
      <Dropdown className="canvas-overflow order-md-1 mt-3">
        <Dropdown.Toggle as={CustomToggle}>
          <FontAwesomeIcon icon={faEllipsisH} className="ml-3" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="right">
          <Dropdown.Item
            onClick={() => {
              this.cancelAssign();
              setActiveCanvasMode(VIEW_AS_CUSTOMER);
            }}
          >
            <FontAwesomeIcon fixedWidth icon={faUserFriends} />
            View as customer
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={this.onShowSettings}>
            <FontAwesomeIcon fixedWidth icon={faCog} />
            Project settings
          </Dropdown.Item>
          <Dropdown.Divider />

          {!this.state.isFrontlineEnabledToEdit && isFrontLine ? null : (
            <Dropdown.Item
              onClick={() => {
                this.cancelAssign();
                setActiveCanvasMode(EDIT);
              }}
            >
              <FontAwesomeIcon fixedWidth icon={faPen} />
              Edit project
            </Dropdown.Item>
          )}

          <Dropdown.Divider />
          {this.state.canvas.status !== PAUSED ? (
            <Dropdown.Item onClick={this.togglePauseCanvas}>
              <FontAwesomeIcon fixedWidth icon={faPause} />
              Pause project
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={this.unPauseCanvas}>
              <FontAwesomeIcon fixedWidth icon={faPause} />
              Unpause project
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          <Dropdown.Item onClick={this.toggleDeleteCanvasConfirm}>
            <FontAwesomeIcon fixedWidth icon={faTrash} />
            Delete project
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  render() {
    let title = null;
    if (this.state.customer) {
      title = this.state.customer.name;
    } else if (this.props.router.location.state && this.props.router.location.state.name) {
      title = this.props.router.location && this.props.router.location.state.name;
    }

    const handleHideMoreInfo = () => {
      this.setState({ showMoreInfo: false });
      this.loadCustomer();
    };

    return (
      <FullContext.Consumer>
        {({ setActiveCanvasMode, isEditMode, isViewAsCustomer, userData, roleAtleast }) => {
          if (!this.state.userData) {
            this.setState({
              userData,
            });
          }

          return (
            <>
              <PageHeader
                title={<TitleContainer>{title}</TitleContainer>}
                loading={this.state.loading}
                upName="Projects"
                upPath={`/accounts/${this.customerId}`}
                className="canvas-page-header"
                border
              >
                {this.state.customer && (
                  <>
                    {this.headerOverflow(isViewAsCustomer, roleAtleast)}
                    {this.headerDetail(isViewAsCustomer, userData.email, roleAtleast)}
                  </>
                )}
              </PageHeader>
              {!this.state.loading && (
                <MyTouchpointToggle state={this.state} onUpdated={this.toggleShowMyTouchpoints} />
              )}

              <AlertDialog
                visible={isEditMode}
                title="Edit Project View"
                info="You are in project edit mode. You can add, delete, reorder steps, and change dependencies to customize this project. Modifications to the project may change your project end date."
                btnLabel="Exit"
                top="5.625rem"
                onClick={() => setActiveCanvasMode(DEFAULT)}
              />

              <AlertDialog
                visible={isViewAsCustomer}
                title="Viewing as customer"
                btnLabel="Exit"
                top="3.625rem"
                onClick={() => setActiveCanvasMode(DEFAULT)}
              />

              {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
              {this.state.journeys && this.canvasDetail(userData.email)}

              {!!this.state.canvas && (
                <MoreInfoContainer
                  show={this.state.showMoreInfo}
                  canvas={this.state.canvas}
                  onHide={handleHideMoreInfo}
                />
              )}
              {this.state.showingTouchpoint && (
                <ModalTouchpoint
                  show={!!this.state.showingTouchpoint}
                  onHide={this.touchpointModalOnHide}
                  canvasStartDate={this.state.canvas.startDate}
                  canvasEndDate={this.state.canvas.projectedDate}
                  canvasTargetEnd={this.state.canvas.endDate}
                  dynamicTimeline={this.state.canvas.dynamicTimeline}
                  customerCanvasId={this.customerCanvasId}
                  customerId={this.customerId}
                  touchpoint={this.state.showingTouchpoint}
                  setCustomerTouchpointStatus={this.setCustomerTouchpointStatus}
                  onChecklistUpdate={this.onChecklistUpdate}
                  canvasMembers={this.state.members}
                  members={this.membersForTouchpoint(this.state.showingTouchpoint.id)}
                  addMembersTouchpoint={(model) => this.addMembersTouchpoint(model, userData?.email)}
                  addMembersTaskItem={(id, model) => this.addMembersTaskItem(id, model, userData?.email)}
                  removeMemberTaskItem={(itemId, memId) =>
                    this.removeMemberTaskItem(itemId, memId, userData?.email)
                  }
                  removeMember={(data) => this.removeMemberTouchpoint(data)}
                  onResourcesUpdated={this.handleResourcesUpdated}
                  onFilesUpdated={this.handleFilesUpdated}
                  onIdeasUpdated={this.handleIdeasUpdated}
                  appSettings={this.props.appSettings}
                  canvasMembersById={this.state.canvasMembersById}
                  externalsHaveTaskItemPermissions={this.state.canvas?.externalsHaveTaskItemPermissions}
                  onTouchpointUpdate={this.reloadCanvasTouchpoints}
                  onMembersUpdate={() => this.loadMembers()}
                  customerComments={this.state.customerComments}
                  setNewComments={this.setNewComments}
                  dependentData={this.state.dependentData}
                  mergedSteps={[...this.mergeAllSteps()]}
                />
              )}
              {this.state.showCanvasSettingsModal && (
                <ModalCanvasSettings
                  show={this.state.showCanvasSettingsModal}
                  onHide={this.onHideSettings}
                  canvas={this.state.canvas}
                  onUpdated={this.handleChangeCanvasSettingParam}
                  isTemplate={false}
                />
              )}
              {this.state.deletingCanvas && (
                <ModalConfirm
                  title="Delete project"
                  message={
                    <>
                      Are you sure you want to delete this project?
                      <br />
                      This action cannot be undone.
                    </>
                  }
                  show={this.state.deletingCanvas}
                  onConfirm={this.deleteCanvas}
                  onHide={this.toggleDeleteCanvasConfirm}
                />
              )}
              <ModalConfirm
                show={this.state.showUnchangedStatusModal}
                hideCancel
                title="Can't Change Status"
                confirmText="Continue"
                variant="primary"
                message={`
                Unable to change step status since all the tasks are marked completed.
                Change the marked tasks to update the step status.
              `}
                messageAlign="left"
                innerProps={{
                  maxWidth: '620px',
                  padding: '60px 8px 40px !important',
                }}
                buttonsContainerProps={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
                onConfirm={() => this.setState({ showUnchangedStatusModal: false })}
                onHide={() => this.setState({ showUnchangedStatusModal: false })}
              />
              {this.state.pausingCanvas ? (
                <PauseCanvas
                  customerCanvasId={this.customerCanvasId}
                  show={this.state.pausingCanvas}
                  onHide={this.togglePauseCanvas}
                  pausingCanvasNote={this.state.pausingCanvasNote}
                  handlePauseCanvasNoteUpdates={this.handlePauseCanvasNoteUpdates}
                  onConfirm={this.pauseCanvas}
                />
              ) : null}
            </>
          );
        }}
      </FullContext.Consumer>
    );
  }
}

export default withRouter(CustomerCanvas);
