import * as Api from 'api/Api';
import { REPLACE_OPERATION, OBJECT_TYPE_ENUM } from 'constants';
import { dateMixin } from 'mixins';
import Canvas from './Canvas';
import Touchpoint from './Touchpoint';

class CustomerCanvas extends dateMixin() {
  address = '';
  canvas = new Canvas();
  canvasId = null;
  canvasName = '';
  canvases = [];
  clientTypeId = null;
  clientTypeName = '';
  // TODO: Define Objects or create separate class
  contacts = [];
  currentJourneyId = null;
  currentJourneyName = '';
  currentTouchpointDueDate = null;
  currentTouchpointId = null;
  currentTouchpointName = '';
  // Touchpoint.js
  customerTouchpoints = [];
  dateCreated = '';
  dynamicTimeline = false;
  email = null;
  endDate = null;
  externalId = '';
  id = null;
  inactiveDate = null;
  isArchived = false;
  name = '';
  ownerFirstName = '';
  ownerId = null;
  ownerLastName = '';
  phone = null;
  primaryContactName = '';
  processEndDate = null;
  processStartDate = null;
  revenue = null;
  startDate = null;
  status = '';
  // TODO: Define constants
  timeStatus = '';
  // TODO: Define constants
  type = '';

  constructor(props) {
    super(props);

    if (props) {
      Object.assign(this, props);
    }
  }

  static async getByCustomerAndCustomerCanvasId(customerId, customerCanvasId) {
    const response = await Api.get('Customer/GetCustomerById', {
      id: customerId,
      customerCanvasId,
    });

    // Currently API is returning unrelated canvases and should be filtered.
    // Not sure if unrelated canvases is used somewhere?
    // In CustomerCanvas only the canvas is used though.
    if (response.canvases && response.canvases.length) {
      response.canvas = new Canvas(response.canvases.find(({ id }) => id === customerCanvasId));
    }

    if (response.customerTouchpoints && response.customerTouchpoints.length) {
      response.customerTouchpoints = response.customerTouchpoints
        .filter((data) => data.customerCanvasId === customerCanvasId)
        .map((data) => new Touchpoint(data));
    }

    if (response.canvases && response.canvases.length) {
      response.canvases = response.canvases.map((data) => new Canvas(data));
    }

    return new CustomerCanvas(response);
  }

  get maxProjectedDate() {
    if (!this.customerTouchpoints || !this.customerTouchpoints.length) return null;

    const projectedDates = this.customerTouchpoints.map(({ projectedDate }) => projectedDate);
    return new Date(Math.max(...projectedDates));
  }

  static async getReviewProjectEndDate(customerCanvasId, customerTouchpointId) {
    const { customerCanvas } = await Api.get('Project/ReviewProjectEndDate', {
      customerCanvasId,
      customerTouchpointId,
    });

    return customerCanvas?.endDate;
  }

  static async getLookaheadDates(touchpoint) {
    return await Api.post('Touchpoint/GetLookaheadDates', touchpoint);
  }

  static async updateCanvasStartDate(payload, params) {
    return await Api.patch('Customer/PartialCustomerCanvasUpdate', payload, { params });
  }

  static async setCustomerTouchpointStatus(status, id) {
    const response = await Api.post('Customer/SetCustomerTouchpointStatus', status, {
      params: { customerTouchpointId: id },
    });
    return response;
  }

  static async addUsersToCustomerTouchpoint(addMembersModel, customerTouchpointId) {
    return await Api.post('Customer/AddUsersToCustomerTouchpoint', addMembersModel, {
      params: { customerTouchpointId },
    });
  }

  static async getCanvasTags(customerCanvasId) {
    return await Api.get('Project/GetTags', { customerCanvasId });
  }

  static async updateCustomerCanvas(id, data) {
    const { field, value } = data;

    const request = [
      {
        operationType: OBJECT_TYPE_ENUM.PROJECTS,
        path: field,
        op: REPLACE_OPERATION,
        value,
      },
    ];

    const response = await Api.patch(`Customer/PartialCustomerCanvasUpdate`, request, {
      params: { customerCanvasId: id },
    });

    return response;
  }

  static async deleteActiveProjectPhase(customerCanvasId, phaseId) {
    return await Api.deleteRequest(`Project/${customerCanvasId}/Phase/${phaseId}`);
  }
}

export default CustomerCanvas;
