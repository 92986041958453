import { DateHeader } from '../Shared/DateChange/styles';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shortDate } from 'helpers/DateHelper';
import { Touchpoint } from 'models';
import { useContext } from 'react';
import FullContext from 'stores/Full/fullContext';

export default function DisplayDate(
  { touchpoint, dueDate, setShowEditDateModal } = { touchpoint: new Touchpoint() }
) {
  const { isEditMode, isExternal, isViewAsCustomer } = useContext(FullContext);
  const hasEditConditions =
    (isEditMode && !touchpoint?.dateTimeCompleted) ||
    (!isEditMode && touchpoint?.dateTimeCompleted) ||
    touchpoint?.dateToBeScheduled;
  let statusText = `DUE BY ${shortDate(dueDate)}`;
  if (touchpoint?.customerTouchpointStatus === 'Completed') {
    statusText = ` COMPLETED ON ${shortDate(touchpoint.dateTimeCompleted || new Date())}`;
  } else if (touchpoint?.dateToBeScheduled && touchpoint?.dateIsScheduled) {
    statusText = isEditMode
      ? `${touchpoint.scheduledDate ? `SCHEDULED FOR ${shortDate(touchpoint.scheduledDate)} | ` : ''
      }DUE BY ${shortDate(dueDate)}`
      : `ON ${shortDate(dueDate)}`;
  }

  return (
    <>
      {isExternal ? (
        <DateHeader isExternal={true}>
          {!touchpoint?.dateToBeScheduled && !touchpoint?.dateIsScheduled && `DUE BY ${shortDate(dueDate)}`}
          {touchpoint?.dateToBeScheduled && !touchpoint?.dateIsScheduled && touchpoint.isIncomplete && (
            <span className="scheduleText">TO BE SCHEDULED</span>
          )}
          {touchpoint?.dateToBeScheduled && touchpoint?.dateIsScheduled && `ON ${shortDate(dueDate)}`}
        </DateHeader>
      ) : (
        <DateHeader
          isExternal={false}
          onClick={() => hasEditConditions && setShowEditDateModal(true)}
          hasEditConditions={hasEditConditions}
        >
          {touchpoint?.dateToBeScheduled &&
            (!touchpoint?.dateIsScheduled || !touchpoint.scheduledDate) &&
            touchpoint.isIncomplete && (
              <>
                <span className="scheduleText">TO BE SCHEDULED</span>
                <span className="separator">|</span>
              </>
            )}
          {statusText}
          {touchpoint?.noDueDate && !isExternal && ' (LAST DAY OF PROJECT)'}
          {hasEditConditions && !isViewAsCustomer && (
            <FontAwesomeIcon icon={faPencil} className="ml-2" style={{ cursor: 'pointer' }} />
          )}
        </DateHeader>
      )}
    </>
  );
}
