import { useCallback, useEffect, useReducer } from 'react';
import FullReducer from './fullReducer';
import FullContext from './fullContext';
import { DEFAULT, EDIT, VIEW_AS_CUSTOMER } from 'constants/mode';
import { Full } from 'models';
import { ADMIN, CONTRIBUTOR, CREATOR, EXTERNAL, FRONT_LINE, SUPERUSER, ROLE_ORDER } from 'constants';
import { accessToken } from 'helpers/CommonHelper';
import { useLocation } from 'react-router-dom';

const FullState = ({ children }) => {
  const location = useLocation();
  const initialPathname = location.pathname ?? '';
  const initialRoute = { to: initialPathname, from: initialPathname };

  const initialState = {
    organizationData: {},
    activeCanvasMode: DEFAULT,
    userData: {},
    appSettings: {},
    isReminderUpdated: false,
    isCanvasUpdated: false,
    route: initialRoute,
    totalAccounts: null,
  };

  const [state, dispatch] = useReducer(FullReducer, initialState);

  const populateOrganizationData = (data) => {
    dispatch({
      type: 'populate_organization_data',
      payload: data,
    });
  };

  const populateAppSettings = (data) => {
    dispatch({
      type: 'populate_app_settings',
      payload: data,
    });
  };

  const populateTotalAccounts = (data) => {
    dispatch({
      type: 'populate_total_accounts',
      payload: data,
    });
  };

  const setActiveCanvasMode = (data) => {
    dispatch({
      type: 'set_active_canvas_mode',
      payload: data,
    });
  };

  const populateUserData = (data) => {
    dispatch({
      type: 'populate_user_data',
      payload: data,
    });
  };

  const changeAvatar = (data) => {
    dispatch({
      type: 'change_avatar',
      payload: data,
    });
  };

  const setReminderState = (data) => {
    dispatch({
      type: 'set_reminder_state',
      payload: data,
    });
  };

  const setCanvasState = (data) => {
    dispatch({
      type: 'set_canvas_state',
      payload: data,
    });
  };

  const trackRoute = useCallback((newRoute) => {
    dispatch({
      type: 'update_route',
      payload: newRoute,
    });
  }, []);

  const isLoggedIn = !!accessToken;

  const fetchUserData = useCallback(async () => {
    const {
      customers,
      avatar,
      email,
      fullName,
      id,
      role,
      salesforceIntegrated,
      surveyMonkeyIntegrated,
      userId,
      organizationName,
      externalEmailProvider,
      dateCreated,
      isConfirmed,
    } = await Full.getUserData();

    const userData = {
      allOpportunities: false,
      appSettings: null,
      availableCustomers: JSON.stringify(customers),
      avatar,
      email,
      externalEmailProvider,
      dateCreated,
      firstName: fullName?.split(' ')[0],
      fullName,
      id,
      role,
      salesforceIntegrated,
      surveyMonkeyIntegrated,
      userId,
      externalUserOrg: role === EXTERNAL ? organizationName : null,
      isConfirmed,
    };
    if (!isLoggedIn) {
      sessionStorage.role = role;
    }
    populateUserData(userData);
  }, [isLoggedIn]);

  const isEditMode = state.activeCanvasMode === EDIT;
  const isViewAsCustomer = state.activeCanvasMode === VIEW_AS_CUSTOMER;
  const isSuperuser = state.userData.role === SUPERUSER;
  const isAdmin = state.userData.role === ADMIN;
  const isCreator = state.userData.role === CREATOR;
  const isFrontLine = state.userData.role === FRONT_LINE;
  const isContributor = state.userData.role === CONTRIBUTOR;
  const isExternal = state.userData.role === EXTERNAL;

  useEffect(() => {
    trackRoute(location.pathname);
  }, [location.pathname, trackRoute]);

  useEffect(() => {
    if (
      Object.keys(state.organizationData).length &&
      Object.keys(state.userData).length &&
      Object.keys(state.appSettings).length
    ) {
      if (isExternal) return;

      window.pendo.initialize({
        visitor: {
          id: state.userData?.userId,
          email: state.userData?.email,
          userrole: state.userData?.role,
          creationdate: new Date(state.userData?.dateCreated).toISOString(),
          externalemailprovider: state.userData?.externalEmailProvider,
        },
        account: {
          id: state.organizationData?.activeOrganization?.uuid,
          name: state.organizationData?.activeOrganization?.name,
          plantype: state.appSettings?.featureFriendlyName,
          creationdate: new Date(state.organizationData?.activeOrganization?.dateCreated).toISOString(),
          logouploaded: state.appSettings?.logo,
          experiments: JSON.stringify(state.appSettings?.experiments),
        },
      });
    }
  }, [state.organizationData, state.userData, state.appSettings, isExternal]);

  const roleAtleast = (role) => {
    if (state.userData?.role) {
      return ROLE_ORDER.indexOf(state.userData?.role) <= ROLE_ORDER.indexOf(role);
    } else {
      const userRole = sessionStorage.role;
      sessionStorage.removeItem('role');
      return userRole && ROLE_ORDER.indexOf(userRole) <= ROLE_ORDER.indexOf(role);
    }
  };

  return (
    <FullContext.Provider
      value={{
        organizationData: state.organizationData,
        userData: state.userData,
        appSettings: state.appSettings,
        isReminderUpdated: state.isReminderUpdated,
        isCanvasUpdated: state.isCanvasUpdated,
        route: state.route,
        totalAccounts: state.totalAccounts,
        fetchUserData,
        setReminderState,
        setCanvasState,
        changeAvatar,
        populateUserData,
        populateOrganizationData,
        populateAppSettings,
        populateTotalAccounts,
        setActiveCanvasMode,
        roleAtleast,
        isEditMode,
        isViewAsCustomer,
        isSuperuser,
        isAdmin,
        isCreator,
        isFrontLine,
        isContributor,
        isExternal,
      }}
    >
      {children}
    </FullContext.Provider>
  );
};

export default FullState;
