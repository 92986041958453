import { useEffect, useState } from 'react';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from '../LargePadding';
import Checkbox from './Checkbox';
import Content from './Content';

export default function ModalConfirm({
  show,
  onHide,
  title,
  onConfirm,
  confirmLoading,
  cancelText,
  confirmText,
  variant,
  innerProps,
  buttonsContainerProps,
  message,
  messageAlign,
  checkboxProps,
  autoButtonsWidth,
  hideCancel,
  innerclassname,
  buttonclassname,
  disabledConfirm,
  resetOnConfirm = false
}) {
  const [loading, setLoading] = useState(false);
  const [unmounted, setUnmounted] = useState(false);

  useEffect(() => {
    return () => {
      setUnmounted(true);
    };
  }, []);

  async function handleOnConfirm() {
    setLoading(true);
    await onConfirm();
    if (!unmounted || resetOnConfirm) {
      setLoading(false);
    }
  }

  return (
    <ModalLargePadding
      innerclassname={innerclassname}
      innerProps={innerProps}
      buttonclassname={buttonclassname}
      buttonsContainerProps={buttonsContainerProps}
      show={show}
      onHide={onHide}
      content={
        <div className="text-center">
          <h1 className="mb-3">{title}</h1>
          <Content message={message} align={messageAlign} />
          <Checkbox {...checkboxProps} />
        </div>
      }
      buttons={
        <>
          {!hideCancel ? (
            <>
              <ModalLargePaddingCancelButton
                text={cancelText || 'Cancel'}
                onClick={onHide}
                autoWidth={autoButtonsWidth}
              />
            </>
          ) : null}
          <ModalLargePaddingConfirmButton
            onClick={handleOnConfirm}
            variant={variant || 'danger'}
            loading={confirmLoading || loading}
            text={confirmText || 'Delete'}
            autoWidth={autoButtonsWidth}
            disabled={disabledConfirm}
          />
        </>
      }
    />
  );
}
