import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Integration, Gmail } from 'models';
import { getUrlParams, userLoggedIn } from 'helpers/RouteHelper';
import AuthContext from 'stores/Auth/authContext';
import googleBtnImage from '../../img/btn_google_signin_dark_normal_web.png';
import FullContext from 'stores/Full/fullContext';
import ModalConfirm from 'components/Modals/Confirm';

const StatusContainer = styled.div`
  max-width: 700px;
`;

const StyledButton = styled(({ isVisible, ...rest }) => <Button {...rest} />)`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const StyledImg = styled.img`
  visibility: ${({ hasGoogleProfile }) => (hasGoogleProfile ? 'visible' : 'hidden')};
`;

const EmailConnect = ({
  nylasProfile,
  googleProfile,
  userProfile: { firstName, lastName, email: userEmail },
  loadUserProfile,
  isTrialMode,
}) => {
  let name = `${firstName} ${lastName}`;
  let email = userEmail;
  let image = undefined;
  const hasGoogleProfile = !!googleProfile;
  const hasNylasProfile = !!nylasProfile;
  const connectingText = 'Connecting...';
  const isConnected = hasGoogleProfile || hasNylasProfile;
  const infoText = hasGoogleProfile
    ? 'Update your email authorization now in preparation for future improvements like viewing email in app, message read receipts and more.'
    : 'Connect your email account to have automated emails sent from your email address and receive replies directly to your email inbox.';
  const { code = '' } = getUrlParams();
  if (hasGoogleProfile) ({ name, email, image } = googleProfile);
  if (hasNylasProfile) ({ name, email } = nylasProfile);

  const dataStore = useContext(AuthContext);
  let connectEmail, disconnectEmail, getNylasToken, isTenantAllowed;
  if (dataStore) ({ connectEmail, disconnectEmail, getNylasToken, isTenantAllowed } = dataStore);

  const [nylas, setNylas] = useState(hasNylasProfile ? new Integration(nylasProfile) : null);
  const [connectionStatus, setConnectionStatus] = useState(isConnected ? 'Connected' : '');
  const [hasError, setErrorStatus] = useState(false);
  const { userData } = useContext(FullContext);
  const [trialModal, setTrialModal] = useState(false);
  const history = useNavigate();

  const isBtnConnectShown = !hasGoogleProfile && !nylas && !hasError && connectionStatus !== connectingText;

  const setAccessToken = useCallback(async () => {
    if (userLoggedIn(userData.accessToken) && isTenantAllowed) {
      const accessToken = await getNylasToken();

      connectEmail(accessToken);
    }
  }, [connectEmail, getNylasToken, isTenantAllowed, userData.accessToken]);

  useEffect(() => {
    (async () => {
      if (code && !nylas) {
        try {
          const urlState = window.location.search.slice(1).split('&')[1].split('=')[1];
          if (urlState === sessionStorage.stateParameter) {
            setConnectionStatus(connectingText);
            const { provider, sync_state: syncState } = await Integration.connect(code);
            setNylas(new Integration({ provider, syncState }));
            setConnectionStatus('Connected');
            await loadUserProfile();
          } else {
            await Integration.disconnect();
            disconnectEmail && disconnectEmail();
            window.location.replace(`${window.location.origin}${window.location.pathname}`);
          }
        } catch (e) {
          console.error(e);
          setConnectionStatus('Error');
          setErrorStatus(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nylas, code]);

  useEffect(() => {
    if (!nylasProfile || isBtnConnectShown) {
      disconnectEmail && disconnectEmail();
      return;
    }

    setAccessToken();
  }, [setAccessToken, disconnectEmail, isBtnConnectShown, nylasProfile]);

  useEffect(() => {
    if (hasGoogleProfile) setConnectionStatus('Connected');
  }, [hasGoogleProfile]);

  const handleUpdate = async () => {
    await Gmail.disconnect();
    await Integration.authorize(userData?.email);
  };

  const handleDisconnect = async () => {
    await Integration.disconnect();
    disconnectEmail();

    if (hasError || code) return (window.location.href = '/profile');

    setNylas(null);
  };

  const trialProps = {
    title: 'Upgrade to Access',
    confirmText: 'Manage Subscription',
    variant: 'primary',
    messageAlign: 'left',
    innerProps: {
      maxWidth: '520px',
      padding: '70px 120px 40px',
    },
    buttonsContainerProps: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const ConnectBtn = () => (
    <Button
      className="mt-4"
      onClick={() => {
        isTrialMode ? setTrialModal(true) : Integration.authorize(userData?.email);
      }}
      variant="primary"
    >
      Connect
    </Button>
  );

  const ConnectGoogleBtn = () => (
    <img
      className="mt-4"
      src={googleBtnImage}
      onClick={() => {
        isTrialMode ? setTrialModal(true) : Integration.authorize(userData?.email);
      }}
      alt="Sign In with Google"
    />
  );

  const UpdateBtn = () =>
    hasGoogleProfile && (
      <Button className="d-flex align-items-center ml-4" variant="primary" onClick={handleUpdate}>
        Update
      </Button>
    );

  const DisconnectBtn = () => (
    <StyledButton
      className="d-flex align-items-center ml-4"
      variant="danger"
      onClick={handleDisconnect}
      isVisible={connectionStatus !== connectingText && (!!nylas || hasError)}
    >
      Disconnect
    </StyledButton>
  );

  return (
    <>
      <ModalConfirm
        show={trialModal}
        message={
          <>
            <p>
              Connect your email address to allow Stagebase to send email on your behalf. Your customers will
              received templated emails, notifications, and status updates from your inbox.
            </p>
            <p>Upgrade your subscription to access this feature.</p>
          </>
        }
        autoButtonsWidth
        onConfirm={() => {
          setTrialModal(false);
          history('/settings#billing');
        }}
        onHide={() => setTrialModal(false)}
        {...trialProps}
      />
      <h2 className="settings-section-title">Email</h2>
      <div className="small-info">{infoText}</div>
      {isBtnConnectShown ? (
        isTenantAllowed ? (
          <>
            <ConnectGoogleBtn />
            <div className="small-info mt-4">Auto-detect another provider</div>
            <ConnectBtn />
          </>
        ) : (
          <ConnectBtn />
        )
      ) : (
        <>
          <hr />
          <StatusContainer className="d-flex my-3 justify-content-between">
            <div className="d-flex align-items-center">
              <StyledImg
                data-testid="userAvatar"
                className="avatar-circle mr-3"
                src={image}
                alt="google avatar"
                hasGoogleProfile={hasGoogleProfile}
              />
              <div>
                <div>
                  <strong>{name}</strong>
                </div>
                <div className="color-completed">{email}</div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className={`color-completed ${hasError ? 'text-danger' : ''}`}>{connectionStatus}</div>
              <UpdateBtn />
              <DisconnectBtn />
            </div>
          </StatusContainer>
          <hr />
        </>
      )}
    </>
  );
};

export default EmailConnect;
