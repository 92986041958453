import { differenceInCalendarDays } from 'date-fns';

export function getDateFromServerString(str) {
  // if (!str.endsWith('Z')) {
  //   // not sure why server is returning some dates with Z and some without
  //   str += '';
  // }
  return new Date(str);
}

export function getNextDayOfWeek(dow) {
  // dow = 0-6 (sun-sat)
  const date = new Date();
  date.setDate(date.getDate() + ((dow + (7 - date.getDay())) % 7));
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function shiftDateByOffset(date) {
  // this takes a date that is in local time (2020-12-18T07:00:00.000Z)
  // and returns a new date shifted by our tz offset (2020-12-18T00:00:00.000Z)
  return date && new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function formatDateTime(date, dateOptions, timeOptions) {
  const _dateOptions = { month: 'short', day: 'numeric', ...(dateOptions || {}) };
  const _timeOptions = { hour: 'numeric', minute: 'numeric', ...(timeOptions || {}) };
  const formattedDate = date.toLocaleString('en-US', _dateOptions);
  return {
    time: date.toLocaleString('en-US', _timeOptions),
    date: formattedDate.toUpperCase(),
    formattedDate,
  };
}

export function formatDateString(str, dateOptions, timeOptions) {
  let date = getDateFromServerString(str);
  return formatDateTime(date, dateOptions, timeOptions);
}

export function shortDate(date = new Date()) {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date && date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
}

export function findNumberOfDays(newDate, currentDate) {
  return differenceInCalendarDays(newDate, currentDate);
}

export function getDurationDays(durationOption, durationAmount) {
  let parsed = 0;
  if (durationOption === 'Days') {
    parsed = parseInt(durationAmount);
  } else if (durationOption === 'Weeks') {
    parsed = parseInt(durationAmount) * 7;
  } else if (durationOption === 'Months') {
    parsed = parseInt(durationAmount) * 30;
  }

  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
}

export const toLocalTimezone = (utc) => {
  return new Date(utc + 'Z');
};

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

const isIsoDateString = (value) => {
  return value && typeof value === 'string' && isoDateFormat.test(value);
};

export const handleDates = (body) => {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = toLocalTimezone(value);
    else if (typeof value === 'object') handleDates(value);
  }
};
